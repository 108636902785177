import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Page from "~common/components/Page";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Page seo={{
  title: "Refunds"
}}>
    <Page.Header downArrows>
      <Page.Header.Title>Refunds</Page.Header.Title>
    </Page.Header>
    <Page.Body sx={{
    py: [5, 6]
  }}>{children}</Page.Body>
  </Page>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <h1>{`Refund`}</h1>
    <p>{`If you have received a damaged product please contact our support service at `}<a parentName="p" {...{
        "href": "mailto:support@alamgesto.xyz"
      }}>{`support@alamgesto.xyz`}</a>{` providing details and images related to the product.
You have a 14 days deadline from the receipt of the order to report your problem with the product received. Expired 14 days, the refund will no longer be possible.`}</p>
    <h2>{`1.1`}</h2>
    <p>{`The Customer has the right to receive a refund on the purchase of a product without being subject to any penalties, within fourteen (14) days from the day the product/s purchased on `}<a parentName="p" {...{
        "href": "http://www.almagesto.xyz"
      }}>{`www.almagesto.xyz`}</a>{` has been received.
It is not possible to exchange the selected item for another one.`}</p>
    <h2>{`1.2`}</h2>
    <p>{`To report the problems encountered with the product, the Customer must contact Almagesto support service sending and e-mail to `}<a parentName="p" {...{
        "href": "mailto:support@almagesto.xyz."
      }}>{`support@almagesto.xyz.`}</a>{`
Within 48h the Customer will be informed if they have the conditions to repeat the order or to be refunded. Whether the product is actually damaged, the Almagesto support service will ask the customer if they prefer to be refunded or to repeat the order.`}</p>
    <h2>{`1.3`}</h2>
    <p>{`In the case of a return, the Customer must return the products to the Seller, delivering them within fourteen (14) calendar days, commencing on the day the products were received.`}</p>
    <h2>{`1.4`}</h2>
    <p>{`The Customer’s only onus is to answer the Almagesto support service e-mail in order to confirm the condition of the received product and to express its decision of refund or repetition of the order.`}</p>
    <h2>{`1.5`}</h2>
    <p>{`If the Customer decides to repeat the order of the received product, he will not have to make any additional payment. Making this decision the Customer will not be refunded but will receive a new copy of the product previously ordered.`}</p>
    <h2>{`1.6`}</h2>
    <p>{`If the Customer decides to return the product he/she must pay the costs to return the purchased products, which shall be at their expense.`}</p>
    <h2>{`1.7`}</h2>
    <p>{`The Right of Refund – in addition to compliance with the terms and methods described in previous points – is intended as correctly exercised if all the following conditions have been satisfied:
a. The Refund Procedure can be activated ONLY by reporting that the Customer has received a damaged product by providing adequate written and photographic documentation which must be sent at `}<a parentName="p" {...{
        "href": "mailto:support@almagesto.xyz"
      }}>{`support@almagesto.xyz`}</a>{`, within fourteen (14) days from when the products are received;
b. products must not have been used, framed, worn and/or washed;
c. if the products are returned they must be in their original packaging;
d. returned products must be delivered within fourteen (14) days, effective from the day the Customer informed the Seller of their decision to return the product;
e. products must not be damaged on purpose by the Customer.`}</p>
    <h2>{`1.8`}</h2>
    <p>{`If the Right of Refund is exercised in accordance with the methods and terms indicated in this page, and the customer will apply for the refund, the Seller will refund any amounts already collected for the purchase of the products, in accordance with the methods and terms set forth.`}</p>
    <h2>{`1.9`}</h2>
    <p>{`The amounts will be refunded to the Customer as quickly as possible, and in any case within thirty (30) days from the date the Seller becomes aware that the Right of Withdrawal has been exercised. The Seller will activate the refund procedure, after checking the correct fulfilment of the terms and conditions indicated above.
If the methods and terms for the exercise of the Right of Refund are not respected, the Customer will not be entitled to a refund of the amounts already paid to the Seller.
Within 14 days of sending the email in which the Seller informs the Customer of the non-acceptance of the return, the Customer may decide to re-acquire, at their own expense, the products previously received, having informed the Seller, in accordance with the instructions provided.`}</p>
    <p>{`If the conditions in the previous paragraphs are not respected, the Customer will not be entitled to a full refund of the amounts already paid to the Seller.
The Customer will in fact be responsible for the reduced value of the received products, resulting from use other than that authorised by the Seller, thus allowing the Seller to evaluate the nature and characteristics of the products themselves.
In this circumstance, a percentage between 10 and 90 percent of the amounts paid by the Customer to the Seller to purchase the returned products will be deducted from the refund, in accordance with that specifically communicated via email, by the Seller. Within 14 days of sending the email communicating the amount deducted from the refund, the Customer may decide to re-acquire, at their own expense, the products in the condition in which they were returned to the Seller, having informed the Seller, in accordance with the instructions provided.
Alternatively, the Seller may decide to keep an amount corresponding to the percentage deducted from the refund.
Refund Times & Methods`}</p>
    <h2>{`2.1`}</h2>
    <p>{`After the report of the damaged products, the seller will make the necessary checks relating to their compliance with the conditions and terms indicated in this page.
In the event that the checks are concluded positively, the Seller will send the Customer, via e-mail, the relative confirmation of acceptance of the request to be refunded or to reorder the product at no additional cost.`}</p>
    <h2>{`2.2`}</h2>
    <p>{`Whatever the payment method used by the customer, the refund is activated by the Seller as quickly as possible and in any case within thirty (30) days from the actual return of the goods to the Seller.
If there is no correspondence between the recipient of the products indicated in the order form and who made the payment of the sums due for their purchase, the reimbursement of the sums, in case of exercise of the right of withdrawal, will be made by the Seller, in in any case, towards the person who made the payment.`}</p>
    <h2>{`2.3`}</h2>
    <p>{`The value date of the re-credit is the same as the debit; consequently the Client will not suffer any loss in terms of bank interest.`}</p>


    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      